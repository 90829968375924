import {
  CONVEYANCE_TYPES,
  createRedemption,
  defaultStoredAddress,
  deleteMessage,
  deleteStoredAddress,
  fetchLoyaltyState,
  fetchMessages,
  fetchRedeemables,
  fetchRedemptions,
  fetchRewardsHistory,
  getRewards,
  getStoredAddresses,
  getUser,
  getSavedCards,
  markMessageAsRead,
  redeemReceiptBarcode,
  updateMe,
  favoriteOrders,
  pastOrders,
  favoritesSave,
  favoritesDelete,
  setConveyance,
  validateBasket,
  removeSavedCard,
  getFavoriteLocations,
  deleteFavoriteLocation,
  saveFavoriteLocation,
  validateLoyaltyToken,
} from '@koala/sdk/v4';
import { all, call, put, select, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import actions from './actions';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import {
  ERROR_MESSAGES,
  LOG_EVENTS,
  K_ANALYTICS_EVENTS,
  API_CONVEYANCE_TYPES,
} from '@/constants/events';
import { LOYALTY_FEATURES, REWARD_TYPE } from '@/constants/loyalty';
import globalActions from '@/redux/global/actions';
import { locationsActions } from '@/redux/locations/actions';
import orderStatusActions from '@/redux/orderStatus/actions';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { getOrigin } from '@/utils';
import { determineBasketFulfillment } from '@/utils/basket';
import { assembleDeliveryObjectFromState, getIdsFromState } from '@/utils/checkout';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { getLoyaltyInfoFromState } from '@/utils/loyalty';
import { fetchOrderDetails } from '@/utils/orders';
import { safelyGetString } from '@/utils/stringHelpers';

function* fetchMeSaga() {
  try {
    const origin = getOrigin(window.location.host);

    /*
     * If we don't have a loyalty token (aka: a token with a `refresh_token` key), we should
     * skip this saga, otherwise we are requesting a ton of unnecessary api calls from the ordering api
     */
    if (!validateLoyaltyToken(origin)) {
      yield put({ type: actions.ME_STOP_LOADING });
      return;
    }

    const client = createHttpClient({ origin });

    const response: SagaReturnType<typeof getUser> = yield call(getUser, {
      client,
    });

    /*
     * `fetchMe` will return undefined if there is no matching user, so we should bail out of this
     * saga to avoid more unnecessary calls
     */
    if (response.data === undefined) {
      yield put({ type: actions.ME_STOP_LOADING });
      return;
    }

    const state: RootState = yield select();
    const reordersEnabled = state.app.cmsConfig.webConfig.accounts.reorders;
    const androidReordersEnabled = state.app.cmsConfig.webConfig.android_features.reorders;

    yield all([
      put({ type: actions.FETCH_LOYALTY_STATE }),
      put({ type: actions.FETCH_MESSAGES }),
      put({ type: actions.FETCH_REDEEMABLES }),
      put({ type: actions.FETCH_OFFERS }),
    ]);

    // Fetch past orders if reorders are enabled
    // TODO: API does not yet allow us to limit/paginate results
    if (reordersEnabled || androidReordersEnabled) {
      yield put({ type: actions.FETCH_ME_PAST_ORDERS });
    }

    // Success
    yield put({ type: actions.ME_SUCCESS, data: response.data });

    // // Set customer data on our Koala Analytics class
    if (typeof window !== 'undefined') {
      window.KoalaAnalytics.setCustomerData(response.data);
    }
  } catch (error) {
    yield put({ type: actions.ME_FAIL });
  }
}

function* fetchMeFavoriteLocationsSaga() {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof getFavoriteLocations> = yield call(getFavoriteLocations, {
      client,
    });

    yield put(actions.fetchMeFavoriteLocationsSuccess(response.data));

    // Set default active location index
    yield put(locationsActions.setActiveLocation(response.data?.[0] ? response.data[0].id : 0));
  } catch (error) {
    yield put({ type: actions.ME_FAIL });
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your favorite locations.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_FAVORITE_LOCATIONS_ERROR, error, errorResponse);
  }
}

function* updateMeSaga(action: ReturnType<typeof actions.updateMe>) {
  // Delete all null keys from payload before submitting
  const updatedParams = Object.assign({}, action.params);
  Object.keys(updatedParams).forEach(
    // @ts-expect-error `key` can't index `LoyaltyUserUpdateInfo`.
    (key) => updatedParams[key] === null && delete updatedParams[key],
  );

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(updateMe, updatedParams, { client });
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.USER_UPDATE);
    yield put(actions.updateMeSuccess());
    yield put(actions.fetchMe());
    yield put(globalActions.displayToast('Your profile was successfully updated!'));
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error updating your profile.',
      error,
    );
    let errorMessage = errorResponse.message;
    if (errorResponse?.error?.error === 'bad_request') {
      if (errorResponse.error.error_data && errorResponse.error.error_data.failures) {
        const errorMessagesArray = Object.values(errorResponse.error.error_data.failures);
        errorMessage += '<br/>' + errorMessagesArray.join(', ');
      }
    }
    yield put(actions.updateMeFail());
    yield put(globalActions.displayErrorToast(errorMessage));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.UPDATE_ME_FAILURE, error, errorResponse);
  }
}

function* appendUserPhoneSaga(action: ReturnType<typeof actions.appendUserPhone>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const state: RootState = yield select();
    const guestUser = state.app.auth.guestUser;
    const { basketId, locationId } = getIdsFromState(state);
    const organization = state.app.organization;

    // Determine checkout order fulfillment type
    const fulfillmentType = determineBasketFulfillment(state.app.basket.fulfillment?.address);

    // If loyalty is enabled
    if (organization.organization.loyalty_driver_id) {
      // Silently attempt to update the user
      yield call(updateMe, { phone: action.phone }, { client });

      // Right now we're just re-firing on deliveries as we only bail out
      // of the inializeOrder saga if it's a delivery with no phone number
      if (fulfillmentType === CONVEYANCE_TYPES.DELIVERY) {
        const deliveryAddress = assembleDeliveryObjectFromState(state);

        const response: SagaReturnType<typeof setConveyance> = yield call(
          // @ts-expect-error
          setConveyance,
          {
            basketId,
            locationId,
            type: API_CONVEYANCE_TYPES.DELIVERY,
            data: deliveryAddress,
          },
          { client },
        );

        yield call(validateBasket, { basketId: response.id, locationId, guestUser }, { client });
      }

      // KA event
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
        name: LOG_EVENTS.USER_PHONE_ADDED,
      });
    }
  } catch (error) {
    // Silently log error
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );

    yield put(orderStatusActions.initializeOrderReconciliation(errorResponse.message));

    const errorEvent =
      errorResponse?.error?.error === 'unable_to_update_user'
        ? ERROR_MESSAGES.ADD_USER_PHONE_ERROR
        : ERROR_MESSAGES.INIT_CHECKOUT_UNHANDLED_CONVEYANCE_FAILURE;

    // KA event
    fireKAnalyticsError(errorEvent, error, errorResponse);
  }
}

function* fetchMeFavoriteOrdersSaga() {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof favoriteOrders> = yield call(favoriteOrders, { client });

    yield put(actions.fetchMeFavoriteOrdersSuccess(response.data));
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your favorite orders.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));
    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_FAVORITE_ORDERS_ERROR, error, errorResponse);
  }
}

function* fetchMePastOrdersSaga() {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof pastOrders> = yield call(pastOrders, {
      client,
    });

    yield put(actions.fetchMePastOrdersSuccess(response.data));
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your order history.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_ORDER_HISTORY_ERROR, error, errorResponse);
  }
}

function* fetchMeCreditCardsSaga(action: ReturnType<typeof actions.fetchMeCreditCards>) {
  try {
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });
    const savedCards: SagaReturnType<typeof getSavedCards> = yield call(
      getSavedCards,
      action.basketId,
      { client },
    );

    const savedCreditCards = savedCards.filter((card) => card.type === 'creditcard');

    yield put(actions.fetchMeCreditCardsSuccess(savedCreditCards));
  } catch (error) {
    yield put(actions.fetchMeCreditCardsFail());

    // KA event
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your payment information.',
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_PAYMENT_INFORMATION_ERROR, error, errorResponse);
  }
}

function* fetchAddressesSaga() {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof getStoredAddresses> = yield call(getStoredAddresses, {
      client,
    });
    yield put({
      type: actions.FETCH_ADDRESSES_SUCCESS,
      myAddresses: response.data,
    });
  } catch (error) {
    // KA event
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your addresses.',
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_STORED_ADDRESSES_ERROR, error, errorResponse);
  }
}

function* deleteAddressSaga(action: ReturnType<typeof actions.deleteAddress>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield put(actions.toggleStoredAddressesMenu(false));
    yield call(deleteStoredAddress, action.id, { client });
    yield put({ type: actions.FETCH_ADDRESSES });

    // Success message
    const state: RootState = yield select();
    const strings = state.app.cmsConfig.strings;
    const successString = safelyGetString(strings, 'account.addresses_delete_success_message');
    yield put(globalActions.displayToast(successString));

    genericEventHandler(GlobalEvents.ACCOUNT__STORED_ADDRESS_DELETED, {
      name: action.id.toString(),
    });
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error deleting your address. Please try again.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.DELETE_STORED_ADDRESS_ERROR, error, errorResponse);
  }
}

function* defaultAddressSaga(action: ReturnType<typeof actions.defaultAddress>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield put(actions.toggleStoredAddressesMenu(false));
    yield call(defaultStoredAddress, action.id, { client });
    yield put({ type: actions.FETCH_ADDRESSES });

    // Success message
    const state: RootState = yield select();
    const strings = state.app.cmsConfig.strings;
    const successString = safelyGetString(strings, 'account.addresses_set_default_success_message');
    yield put(globalActions.displayToast(successString));
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error setting your default address. Please try again.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.SET_DEFAULT_STORED_ADDRESS_ERROR, error, errorResponse);
  }
}

function* fetchAllLocationsCreditCardsSaga(
  action: ReturnType<typeof actions.fetchAllLocationsCreditCards>,
) {
  try {
    const pastOrders = action.pastOrders.slice(0);
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });

    /** @TODO improve `locationBaskets` type. */
    const locationBaskets = {};
    const locationsCreditCards = [];
    const foundCards: string[] = [];
    for (const order of pastOrders) {
      // @ts-expect-error
      const key = `basket_${order.store_location_id}`;
      // Only fetch cards for each location
      // @ts-expect-error `string` can't index `{}`.
      if (!locationBaskets[key]) {
        const savedCards: SagaReturnType<typeof getSavedCards> = yield call(
          getSavedCards,
          // @ts-expect-error order_data is mistyped.
          order.order_data.basket.id,
          { client },
        );
        // @ts-expect-error
        locationBaskets[key] = order.order_data.basket.id;

        // If the location has cards
        if (savedCards.length) {
          const locationCreditCards = {
            // @ts-expect-error
            locationLabel: order.store_location.label,
            // @ts-expect-error
            basketId: order.order_data.basket.id,
            savedCards: savedCards.filter((card) => {
              // Only add creditcard type that hasn't been found yet.
              if (card.type === 'creditcard') {
                if (foundCards.includes(card.id)) {
                  return false;
                }
                foundCards.push(card.id);
                return true;
              }
            }),
          };
          // If after filtering there's still cards for the location.
          if (locationCreditCards.savedCards.length) {
            locationsCreditCards.push(locationCreditCards);
          }
        }
      }
      // One basket id is enough now.
      break;
    }
    yield put(actions.fetchAllLocationsCreditCardsSuccess(locationsCreditCards));
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your payment information.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));
    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_ALL_LOCATIONS_CC_ERROR, error, errorResponse);
  }
}

function* fetchMeOrderDetailsSaga(action: ReturnType<typeof actions.fetchMeOrderDetails>) {
  try {
    const response: SagaReturnType<typeof fetchOrderDetails> = yield call(
      fetchOrderDetails,
      action.id,
      action.pastOrders,
    );

    // @ts-expect-error
    yield put(actions.fetchMeOrderDetailsSuccess(response));
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your order details.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_ORDER_DETAILS_ERROR, error, errorResponse);
  }
}

function* deleteMeCreditCardSaga(action: ReturnType<typeof actions.deleteMeCreditCard>) {
  try {
    const state: RootState = yield select();
    const { basketId } = getIdsFromState(state);
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });

    // Delete Card
    yield call(removeSavedCard, action.id, { client });
    yield put(actions.deleteMeCreditCardSuccess());

    // Refetch cards
    yield put(actions.fetchMeCreditCards(basketId));
  } catch (error) {
    yield put(actions.deleteMeCreditCardFail());
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error removing this credit card.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.DELETE_CREDIT_CARD_ERROR, error, errorResponse);
  }
}

function* deleteFavoriteLocationSaga(action: ReturnType<typeof actions.removeFavoriteLocation>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(deleteFavoriteLocation, action.id, { client });
    yield put(actions.deleteMeFavoriteLocationSuccess());
    yield put(actions.fetchMeFavoriteLocations());

    genericEventHandler(GlobalEvents.ACCOUNT__REMOVE_FAVORITE_LOCATION, {
      name: action.label,
      // @ts-expect-error
      details: action.id,
    });
  } catch (error) {
    yield put(actions.deleteMeFavoriteLocationFail());
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error unfavoriting this location.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.UNFAVORITE_LOCATION_ERROR, error, errorResponse);
  }
}

function* addFavoriteLocationSaga(action: ReturnType<typeof actions.addFavoriteLocation>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(saveFavoriteLocation, action.id, { client });
    yield call(fetchMeFavoriteLocationsSaga);

    const state: RootState = yield select();
    const locations = state.app.locations.list;
    /** @TODO ensure that `location` is defined. */
    // @ts-expect-error
    const locationLabel = locations.find((location) => location.id === action.id).label;

    genericEventHandler(GlobalEvents.ACCOUNT__ADD_FAVORITE_LOCATION, {
      name: locationLabel,
      // @ts-expect-error
      details: action.id,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error favoriting this location.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FAVORITE_LOCATION_ERROR, error, errorResponse);
  }
}

function* deleteFavoriteOrderSaga(action: ReturnType<typeof actions.removeFavoriteOrder>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(favoritesDelete, action.id, { client });
    yield put(actions.deleteMeFavoriteOrderSuccess());
    yield put(actions.fetchMeFavoriteOrders());
    yield put(
      globalActions.displayToast('This order was successfully removed from your favorites!'),
    );
  } catch (error) {
    yield put(actions.deleteMeFavoriteOrderFail());
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error unfavoriting this order.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.UNFAVORITE_ORDER_ERROR, error, errorResponse);
  }
}

function* addFavoriteOrderSaga(action: ReturnType<typeof actions.addFavoriteOrder>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(favoritesSave, action.id, { client });
    yield call(fetchMeFavoriteOrdersSaga);
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error favoriting this order.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FAVORITE_ORDER_ERROR, error, errorResponse);
  }
}

function* fetchOffersSaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch offers if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.INDEX_MY_REWARDS]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof getRewards> = yield call(getRewards, {
      client,
    });

    // Success
    yield put({ type: actions.FETCH_OFFERS_SUCCESS, rewards: response.data });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your rewards.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_REWARDS_ERROR, error, errorResponse);
  }
}

// Punchh V2 only
function* fetchLoyaltyStateSaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch loyalty state if supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  /** @TODO ensure that `SHOW_LOYALTY_STATE` flag is defined. */
  // @ts-expect-error
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.SHOW_LOYALTY_STATE]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchLoyaltyState> = yield call(fetchLoyaltyState, {
      client,
    });

    // Success
    yield put({
      type: actions.FETCH_LOYALTY_STATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching loyalty state:',
      error,
    );

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_LOYALTY_STATE_ERROR, error, errorResponse);
  }
}

function* fetchRedeemablesSaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch redeemables if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.INDEX_REDEEMABLES]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchRedeemables> = yield call(fetchRedeemables, {
      client,
    });

    // Success
    yield put({
      type: actions.FETCH_REDEEMABLES_SUCCESS,
      redeemables: response.data,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your rewards.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_REWARDS_ERROR, error, errorResponse);
  }
}

function* fetchMessagesSaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch messages if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.INDEX_LOYALTY_MESSAGES]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchMessages> = yield call(fetchMessages, { client });

    // Success
    yield put({
      type: actions.FETCH_MESSAGES_SUCCESS,
      messages: response.data,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your messages.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_MESSAGES_ERROR, error, errorResponse);
  }
}

function* fetchRewardsHistorySaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch rewards history if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.SHOW_LOYALTY_HISTORY]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchRewardsHistory> = yield call(fetchRewardsHistory, {
      client,
    });
    // Success
    yield put({
      type: actions.FETCH_REWARDS_HISTORY_SUCCESS,
      rewardsHistory: response.data,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching your points history.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_REWARDS_HISTORY_ERROR, error, errorResponse);
  }
}

function* markMessageAsReadSaga(action: ReturnType<typeof actions.markMessageAsRead>) {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only mark as read if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.MARK_READ_LOYALTY_MESSAGE]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(markMessageAsRead, action.messageId, { client });

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOYALTY_MESSAGE_READ);
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error marking message as read.',
      error,
    );

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.MARK_AS_READ_ERROR, error, errorResponse);
  }

  // Always re-fetch messages in case some error happened that resets the read at state
  yield put({ type: actions.FETCH_MESSAGES });
}

function* deleteMessageSaga(action: ReturnType<typeof actions.deleteMessage>) {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only allow delete message if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.DESTROY_LOYALTY_MESSAGE]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(deleteMessage, action.messageId, { client });

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOYALTY_MESSAGE_DELETE);

    // Fetch messages on successs
    yield put({ type: actions.FETCH_MESSAGES });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error deleting message.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.DELETE_MESSAGE_ERROR, error, errorResponse);
  }
}

function* createRedemptionSagaSuccess(action: ReturnType<typeof actions.createRedemptionSuccess>) {
  try {
    // Determine whether to fire the Reward or Offer analytics event
    const redemptionAnalyticsLabel =
      action.rewardType === REWARD_TYPE.OFFER
        ? K_ANALYTICS_EVENTS.QR_OFFER_VIEW
        : K_ANALYTICS_EVENTS.QR_REWARD_VIEW;

    // KA Event - New Redemption
    fireKAnalyticsEvent(redemptionAnalyticsLabel, {
      name: action.activeRedemption.label,
    });

    // Fetch redemptions, loyalty state, and rewards history on successs
    yield all([
      put({ type: actions.FETCH_REDEMPTIONS }),
      put({ type: actions.FETCH_LOYALTY_STATE }),
      put({ type: actions.FETCH_REWARDS_HISTORY }),
    ]);
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'Encountered an unexpected error while fetching redemptions, loyalty state and rewards history',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));
  }
}

function* createRedemptionFailureSaga(action: ReturnType<typeof actions.createRedemptionFailure>) {
  // Error Notification
  const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
    prepareErrorMessage,
    'There was an error redeeming reward.',
    action.error,
  );

  yield put(globalActions.displayErrorToast(errorResponse.message, true));

  // KA event
  // Determine whether to fire the Reward or Offer analytics event
  const redemptionErrorEventLabel =
    action.rewardType === REWARD_TYPE.OFFER
      ? ERROR_MESSAGES.REDEEM_OFFER_ERROR
      : ERROR_MESSAGES.REDEEM_REWARD_ERROR;

  fireKAnalyticsError(redemptionErrorEventLabel, action.error, errorResponse);
}

function* createRedemptionSaga(action: ReturnType<typeof actions.createRedemption>) {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);

  if (!loyaltyDriverId) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    // First, see if our reward/offer has already been redeemed before creating a new redemption

    // Determine whether to fire the Reward or Offer analytics event
    const redemptionAnalyticsLabel =
      action.rewardType === REWARD_TYPE.OFFER
        ? K_ANALYTICS_EVENTS.QR_OFFER_VIEW
        : K_ANALYTICS_EVENTS.QR_REWARD_VIEW;

    /* If the reward is an offer */
    if (action.rewardType === REWARD_TYPE.OFFER) {
      // Only allow offer redemption if feature supported
      if (
        /** @TODO ensure that `CREATE_REDEMPTION_USING_REWARD` flag is defined. */
        // @ts-expect-error
        !loyaltyFeatureFlags?.[LOYALTY_FEATURES.CREATE_REDEMPTION_USING_REWARD]
      ) {
        return;
      }

      const myOffers: SagaReturnType<typeof getRewards> = yield call(getRewards, { client });

      // Find our offer from the offers array
      const foundOffer = myOffers.data.filter((offer) => offer.id === action.rewardId);

      // Get tracking code from our found offer
      const existingOfferRedemption = foundOffer.length > 0 && foundOffer[0].redemption;

      // Display the tracking code
      if (existingOfferRedemption) {
        yield put({
          type: actions.CREATE_REDEMPTION_SUCCESS,
          activeRedemption: existingOfferRedemption,
        });

        // KA Event - Existing Offer Redemption
        fireKAnalyticsEvent(redemptionAnalyticsLabel, {
          name: existingOfferRedemption.label,
        });
        return;
      }
    }

    /* If the reward is a redeemable */
    if (action.rewardType === REWARD_TYPE.REWARD) {
      // Only allow reward redemption if feature supported
      if (
        /** @TODO ensure that `CREATE_REDEMPTION_USING_REDEEMABLE` flag is defined. */
        // @ts-expect-error
        !loyaltyFeatureFlags?.[LOYALTY_FEATURES.CREATE_REDEMPTION_USING_REDEEMABLE] ||
        !loyaltyFeatureFlags?.[LOYALTY_FEATURES.INDEX_REDEMPTIONS]
      ) {
        return;
      }

      // Fetch redemptions
      const redemptions: SagaReturnType<typeof fetchRedemptions> = yield call(fetchRedemptions, {
        client,
      });

      // Find out if our reward was previously redeemed
      const existingRedemption = redemptions.data.find(
        (redemption) => redemption.redeemable_id === +action.rewardId,
      );

      // If so, set active redemption
      if (existingRedemption) {
        yield put({
          type: actions.CREATE_REDEMPTION_SUCCESS,
          activeRedemption: existingRedemption,
        });

        // KA Event - Existing Reward redemption
        fireKAnalyticsEvent(redemptionAnalyticsLabel, {
          name: existingRedemption.label,
        });
        return;
      }
    }

    const newRedemption: SagaReturnType<typeof createRedemption> = yield call(
      createRedemption,
      action.rewardId,
      action.rewardType,
      { client },
    );

    yield put({
      type: actions.CREATE_REDEMPTION_SUCCESS,
      activeRedemption: newRedemption,
      rewardType: action.rewardType,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_REDEMPTION_FAILURE,
      error,
      rewardType: action.rewardType,
    });
  }
}

function* fetchRedemptionsSaga() {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only fetch redemptions if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.INDEX_REDEMPTIONS]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchRedemptions> = yield call(fetchRedemptions, {
      client,
    });

    // Fetch redemptions on successs
    yield put({
      type: actions.FETCH_REDEMPTIONS_SUCCESS,
      redemptions: response.data,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching redemptions.',
      error,
    );

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_REDEMPTIONS_ERROR, error, errorResponse);
  }
}

function* redeemReceiptBarcodeSaga(action: ReturnType<typeof actions.redeemReceiptBarcode>) {
  const { loyaltyDriverId }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyDriverId) {
    return;
  }

  // Only allow barcode redemption if feature supported
  const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
    yield select(getLoyaltyInfoFromState);
  if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.CLAIM_REWARDS_BY_RECEIPT]) {
    return;
  }

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    // @ts-expect-error
    const { points_earned }: SagaReturnType<typeof redeemReceiptBarcode> = yield call(
      redeemReceiptBarcode,
      action.barcode,
      { client },
    );

    // Success Notification
    if (points_earned > 0) {
      const state: RootState = yield select();
      const strings = state.app.cmsConfig.strings;
      const successString = safelyGetString(strings, 'rewards.barcode_success_message', {
        points: points_earned,
      });
      yield put(globalActions.displayToast(successString));
    }

    // Fetch messages and close form on successs
    yield put({ type: actions.REDEEM_RECEIPT_BARCODE_SUCCESS });
    yield put({ type: actions.FETCH_LOYALTY_STATE });

    // Fire KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.BARCODE_APPLY);
  } catch (error) {
    yield put({ type: actions.REDEEM_RECEIPT_BARCODE_FAILURE });

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error entering this receipt barcode.',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.REDEEM_BARCODE_ERROR, error, errorResponse);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ME, fetchMeSaga);
  yield takeLatest(actions.FETCH_ME_FAVORITE_LOCATIONS, fetchMeFavoriteLocationsSaga);
  yield takeLatest(actions.ADD_FAVORITE_LOCATION, addFavoriteLocationSaga);
  yield takeLatest(actions.REMOVE_FAVORITE_LOCATION, deleteFavoriteLocationSaga);
  yield takeLatest(actions.FETCH_ME_FAVORITE_ORDERS, fetchMeFavoriteOrdersSaga);
  yield takeLatest(actions.ADD_FAVORITE_ORDER, addFavoriteOrderSaga);
  yield takeLatest(actions.REMOVE_FAVORITE_ORDER, deleteFavoriteOrderSaga);
  yield takeLatest(actions.FETCH_ME_PAST_ORDERS, fetchMePastOrdersSaga);
  yield takeLatest(actions.UPDATE_ME, updateMeSaga);
  yield takeLatest(actions.APPEND_USER_PHONE, appendUserPhoneSaga);
  yield takeLatest(actions.FETCH_ME_CREDIT_CARDS, fetchMeCreditCardsSaga);
  yield takeLatest(actions.FETCH_ALL_LOCATIONS_CREDIT_CARDS, fetchAllLocationsCreditCardsSaga);
  yield takeLatest(actions.DELETE_ME_CREDIT_CARD, deleteMeCreditCardSaga);
  yield takeLatest(actions.FETCH_ME_ORDER_DETAILS, fetchMeOrderDetailsSaga);
  yield takeLatest(actions.FETCH_OFFERS, fetchOffersSaga);
  // Punchh V2 only
  yield takeLatest(actions.FETCH_LOYALTY_STATE, fetchLoyaltyStateSaga);
  yield takeLatest(actions.FETCH_REDEEMABLES, fetchRedeemablesSaga);
  yield takeLatest(actions.FETCH_MESSAGES, fetchMessagesSaga);
  yield takeLatest(actions.FETCH_REWARDS_HISTORY, fetchRewardsHistorySaga);
  yield takeLatest(actions.MARK_MESSAGE_AS_READ, markMessageAsReadSaga);
  yield takeLatest(actions.DELETE_MESSAGE, deleteMessageSaga);
  yield takeLatest(actions.CREATE_REDEMPTION, createRedemptionSaga);
  yield takeLatest(actions.FETCH_REDEMPTIONS, fetchRedemptionsSaga);
  yield takeLatest(actions.REDEEM_RECEIPT_BARCODE, redeemReceiptBarcodeSaga);
  yield takeLatest(actions.FETCH_ADDRESSES, fetchAddressesSaga);
  yield takeLatest(actions.DELETE_ADDRESS, deleteAddressSaga);
  yield takeLatest(actions.DEFAULT_ADDRESS, defaultAddressSaga);
  yield takeLatest(actions.CREATE_REDEMPTION_SUCCESS, createRedemptionSagaSuccess);
  yield takeLatest(actions.CREATE_REDEMPTION_FAILURE, createRedemptionFailureSaga);
}
