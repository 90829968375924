import { PAYMENT_TYPES } from '@koala/sdk';
import { type AnyAction } from 'redux';
import orderStatusActions from '../orderStatus/actions';
import actions from './actions';
import { type IPaymentState } from '@/types/paymentInfo';

export const initialState: IPaymentState = {
  storedValueError: '',
  storedValue: null,
  paymentType: null,
  toast: null,
  selectedDigitalWallet: '',
};

export const payment = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.BILLING_ACCOUNT_SET:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.BILLING_ACCOUNT,
          saved_account_id: action.savedAccountId,
        },
      });
    case actions.CREDIT_CARD_SET:
      return Object.assign({}, state, {
        paymentType: {
          ...action.creditCard,
        },
      });
    case actions.PAY_IN_STORE_SET:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.CASH,
        },
      });
    case actions.DIGITAL_WALLET_TOGGLE:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.DIGITAL_WALLET,
          selectedDigitalWallet: action.selectedDigitalWallet,
        },
      });
    case actions.PAYMENT_TYPE_RESET:
      return Object.assign({}, state, {
        paymentType: initialState.paymentType,
        toast: {
          ...state.toast,
          created: false,
          error: '',
        },
      });
    case actions.STORED_VALUE_SET:
      return Object.assign({}, state, {
        storedValue: action.storedValue,
        storedValueError: initialState.storedValueError,
      });
    case actions.STORED_VALUE_ERROR:
      return Object.assign({}, state, {
        storedValue: initialState.storedValue,
        storedValueError: action.message,
      });
    case actions.STORED_VALUE_CLEAR:
    case orderStatusActions.STORED_VALUE_POST:
      return Object.assign({}, state, {
        storedValue: initialState.storedValue,
        storedValueError: initialState.storedValueError,
      });
    case orderStatusActions.INITIALIZE_ORDER:
    case orderStatusActions.DESTROY_ORDER:
      return initialState;
    case actions.TOAST_PAYMENT_INITIALIZE:
      return Object.assign({}, state, {
        toast: { ...state.toast, initialized: action.initialized },
      });
    case actions.TOAST_PAYMENT_ERROR:
      return Object.assign({}, state, {
        toast: { ...state.toast, error: action.message },
      });
    case actions.TOAST_PAYMENT_CREATE:
      const paymentType = state.selectedDigitalWallet
        ? PAYMENT_TYPES.DIGITAL_WALLET
        : PAYMENT_TYPES.CREDIT_CARD_TOKEN;
      return Object.assign({}, state, {
        paymentType: {
          ...state.paymentType,
          card_token: action.paymentIntentId,
          type: paymentType,
          expiry_month: parseInt(action.card.expiry.month, 10),
          expiry_year: parseInt(action.card.expiry.year, 10),
          card_brand: action.card.brand,
          last_four: action.card.lastFour,
        },
        toast: {
          ...state.toast,
          error: '',
          created: true,
        },
      });
    case actions.TOAST_PAYMENT_VALIDATE:
      return Object.assign({}, state, {
        selectedDigitalWallet: action.selectedDigitalWallet,
        toast: {
          ...state.toast,
          isValid: action.isValid,
          error: action.isValid ? '' : state.toast?.error,
        },
      });
    default:
      return state;
  }
};
