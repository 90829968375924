import { Component, type ErrorInfo } from 'react';
import * as ErrorReporter from '@/utils/errorReporter';

interface GenericErrorBoundaryProps {
  fallbackUI?: React.ReactNode;
  children?: React.ReactNode;
}
class GenericErrorBoundary extends Component<GenericErrorBoundaryProps> {
  state = {
    hasError: false,
    fallbackUI: this.props.fallbackUI ?? null,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, 'ERROR');
    console.log(errorInfo, 'ERROR INFO');

    // Fire error event
    ErrorReporter.captureException(error, 'error');
  }

  render() {
    if (this.state.hasError) {
      return (
        this.state.fallbackUI ?? (
          <p>
            Oops, Something went wrong. Refresh the page and try again.
            <button
              onClick={() => window.location.reload()}
              style={{
                background: 'none',
                color: 'inherit',
                textDecoration: 'underline',
                border: 0,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Refresh
            </button>
          </p>
        )
      );
    }

    return this.props.children;
  }
}

export default GenericErrorBoundary;
