interface URLObject {
  data: History;
  url: string;
}

/**
 * Return a URLObject with a list of provided parameters removed
 *
 * @param {params} array
 *
 * @return {URLObject}
 */
export const removeURLParams = (params: string[]): URLObject => {
  const currentURL = new URL(window.location.href);
  const currentParams = new URLSearchParams(currentURL.search);

  params.forEach((param) => {
    currentParams.delete(param);
  });

  const url = `${currentURL.pathname}?${currentParams.toString()}`;

  return {
    data: {
      ...window.history.state,
      as: url,
    },
    url,
  };
};

/**
 * Return a boolean of whether the passed in param is in the URL
 *
 * @param {param} string
 *
 * @return {boolean}
 */
export const getURLParam = (url: string, param: string): boolean => {
  const currentURL = new URL(url);
  const currentParams = new URLSearchParams(currentURL.search);

  return currentParams.get(param) ? true : false;
};

export const isBrandDomain = (): boolean => {
  return (
    typeof window !== 'undefined' &&
    !['onlineorder.site', 'localhost', 'chowly.io'].some((domain) =>
      window.location.host.includes(domain),
    )
  );
};
