import { type UrlObject } from 'url';
import { CONVEYANCE_TYPES, type Basket, PAYMENT_TYPES, type PaymentDetails } from '@koala/sdk';
import {
  createBasket,
  addTip,
  setInstructions,
  setUtensils,
  setConveyance,
  setPickupTime,
  submitOrder,
  validateBasket,
} from '@koala/sdk/v4';
import Router from 'next/router';
import { all, call, put, select, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import actions from './actions';
import { genericEventHandler } from '@/analytics/events';
import { EventNames, GlobalEvents } from '@/analytics/events/constants';
import { InitializationErrorTypes, InitializationSteps } from '@/constants/basket';
import { DATE_FORMAT } from '@/constants/dates';
import { API_CONVEYANCE_TYPES, ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { ALLOWED_URL_PARAMETER_KEYS, DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { ROUTES } from '@/constants/routes';
import basketActions from '@/redux/basket/actions';
import orderStatusActions from '@/redux/orderStatus/actions';
import commerceActions from '@/redux/commerce/actions';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import globalActions from '@/redux/global/actions';
import paymentActions from '@/redux/payment/actions';
import timeslotsActions from '@/redux/timeslots/actions';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { type IPreparedErrorResponse } from '@/types/errors';
import { OrderStatusMessages } from '@/types/orderStatus';
import { getOrigin } from '@/utils';
import { determineBasketFulfillment, patchBasketProductOptions, toDollars } from '@/utils/basket';
import {
  assembleDeliveryObjectFromState,
  assembleOrderDataFromState,
  getIdsFromState,
  getInitializationErrorType,
  getLocationFromState,
} from '@/utils/checkout';
import { isDateSameOrAfter, formatDate } from '@/utils/dates';
import * as ErrorReporter from '@/utils/errorReporter';
import {
  getPersistentParameterValue,
  prepareErrorMessage,
  sessionStorageBasket,
} from '@/utils/global';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { determineHourType } from '@/utils/wantedAt';
import { isUserLoggedInForCheckout } from '@/utils/auth';

/**
 * Initialize Basket
 *
 */
function* initializeOrderSaga(action: ReturnType<typeof actions.initializeOrder>) {
  let response: Basket;
  let wantedAtDay;
  let fulfillmentType: CONVEYANCE_TYPES;
  let initializationStep = InitializationSteps.CREATE_BASKET;
  let basketId: string | null;

  try {
    const state: RootState = yield select();
    const guestUser = state.app.auth.guestUser;
    const localBasket = sessionStorageBasket.get();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    // Determine checkout order fulfillment type
    fulfillmentType = determineBasketFulfillment(state.app.basket.fulfillment?.address);

    /**
     * If a remote basket has been created with the ordering provider,
     * don't create a new basket—just reuse the existing one.
     */
    if (localBasket?.checkoutBasket?.id) {
      basketId = localBasket.checkoutBasket.id;
    } else {
      /**
       * 1. Create a new order
       *
       */
      const basket = patchBasketProductOptions(action.basketOrder);

      response = yield call(createBasket, basket, action.locationId, {
        client,
      });

      basketId = response.id;
    }

    /**
     * 2. Persist wanted at for delivery
     * If a user has persisted a non-asap wanted-at, we need to attempt set it before attempting
     * to set conveyance since conveyance doesn't support wanted-at values
     *
     */
    if (action.wantedAt && action.wantedAt !== DELIVERY_TIME_WANTED_MODES.ASAP) {
      // Ensure the wanted at time is in the future
      if (isDateSameOrAfter(action.wantedAt, new Date())) {
        // Format wantedAtDay from wantedAt timestamp so that we
        // can request basket hours for the appropriate day
        wantedAtDay = formatDate(action.wantedAt, DATE_FORMAT.YEAR_MONTH_DAY);
        initializationStep = InitializationSteps.SET_WANTED_AT;
        yield call(
          setPickupTime,
          {
            basketId,
            locationId: action.locationId,
            wanted_at: action.wantedAt,
          },
          { client },
        );

        // If call succeeds, update the timeslots reducer
        yield put(timeslotsActions.setWantedAt(action.wantedAt));
      }
    }

    /**
     * 3. Set conveyance
     */
    switch (fulfillmentType) {
      case CONVEYANCE_TYPES.DELIVERY:
        const deliveryAddress = assembleDeliveryObjectFromState(state);

        // Catch any outstanding issues with zip code length
        /** @TODO nail down delivery address type to ensure zip_code is present. */
        // @ts-expect-error
        if (deliveryAddress.zip_code?.length > 6) {
          throw new Error('Please ensure that your delivery zip code is not longer than 6 digits.');
        }
        /** @TODO nail down delivery address type. */
        // @ts-expect-error
        if (!deliveryAddress.phone_number) {
          break;
        }

        initializationStep = InitializationSteps.SET_CONVEYANCE;

        response = yield call(
          // @ts-expect-error ensure that `deliveryAddress` is defined.
          setConveyance,
          {
            basketId,
            locationId: action.locationId,
            type: API_CONVEYANCE_TYPES.DELIVERY,
            data: deliveryAddress,
          },
          { client },
        );

        // Update handoff details in redux
        yield put(
          conveyanceModeActions.handoffDetailsSet(CONVEYANCE_TYPES.DELIVERY, {
            instructions: deliveryAddress?.special_instructions ?? '',
          }),
        );
        break;
      case CONVEYANCE_TYPES.DINEIN:
        const table_tent_number: string = getPersistentParameterValue(
          ALLOWED_URL_PARAMETER_KEYS.TABLE_NUMBER,
        );

        /**
         * Initialize order as dine_in, otherwise initialize as pick_up and rely on
         * handoff/conveyance set with table number form field on checkout as normal
         * and require the table_number on basket submission
         */
        if (table_tent_number) {
          yield call(
            setConveyance,
            {
              basketId,
              locationId: action.locationId,
              type: API_CONVEYANCE_TYPES.DINEIN,
              data: { table_tent_number },
            },
            { client },
          );

          // Update handoff details in redux
          yield put(
            conveyanceModeActions.handoffDetailsSet(CONVEYANCE_TYPES.DINEIN, {
              table_tent_number,
            }),
          );
        }

        break;
      default:
        break;
    }

    /**
     * 4. Validate the basket
     *
     */
    initializationStep = InitializationSteps.VALIDATE;
    response = yield call(
      validateBasket,
      { basketId, locationId: action.locationId, guestUser },
      { client },
    );

    // Update checkoutBasket in redux
    yield all([
      put(basketActions.success(response)),
      put(basketActions.basketItemsSyncedWithStore(response.basket_items)),
    ]);

    // Trigger post-initialization calls

    yield put(
      actions.postInitializeOrder(
        action.basketOrder,
        response,
        fulfillmentType,
        wantedAtDay,
        action.wantedAt,
      ),
    );
  } catch (error) {
    const preparedErrorResponse: IPreparedErrorResponse = yield call(
      prepareErrorMessage,
      OrderStatusMessages.INITIALIZING_ORDER_ERROR,
      error,
    );

    let errorEvent;

    // Handle manually thrown errors as well as API errors
    let errorMessage = preparedErrorResponse.message;
    const initErrorType = getInitializationErrorType(errorMessage);

    // Determine if basket reconciliation is needed based on error message
    switch (initErrorType) {
      case InitializationErrorTypes.HANDLED:
        // If it is a handled reconciliation-specific error
        errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_HANDLED_FAILURE;
        break;
      case InitializationErrorTypes.THROTTLING:
        // If it is a throttling-specific error
        errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_THROTTLING_FAILURE;
        break;
      case InitializationErrorTypes.PHONE:
        // If it is a handled reconciliation-specific error
        errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_PHONE_FAILURE;
        break;
      case InitializationErrorTypes.LOCK:
        // If it is a lock-specific error, update log event
        errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_LOCK_FAILURE;
        break;
      case InitializationErrorTypes.UNHANDLED:
        // For unhandled errors, determine if basket reconciliation is needed based on initialization step
        switch (initializationStep) {
          case InitializationSteps.CREATE_BASKET:
            // Create basket failures cannot proceed
            errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_UNHANDLED_BASKET_FAILURE;
            break;
          case InitializationSteps.SET_WANTED_AT:
            // Wanted at failures cannot proceed
            errorMessage = `There was an issue setting your desired ready time. ${errorMessage}`;
            errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_UNHANDLED_WANTED_AT_FAILURE;
            break;
          case InitializationSteps.SET_CONVEYANCE:
            // Conveyance failures cannot proceed
            errorEvent = ERROR_MESSAGES.INIT_CHECKOUT_UNHANDLED_CONVEYANCE_FAILURE;
            break;
          default:
            // Otherwise, attempt to proceed normally by updating checkoutBasket in redux
            /** @TODO modify request changing flow to ensure `response` is defined. */
            // Update checkoutBasket in redux

            // @ts-expect-error ensure `response` is defined.
            yield put(basketActions.success(response));

            // @ts-expect-error ensure `response` is defined.
            if (response?.basket_items) {
              yield put(basketActions.basketItemsSyncedWithStore(response.basket_items));
            }

            // Fire necessary postInitializaOrder calls
            yield put(
              actions.postInitializeOrder(
                action.basketOrder,
                /** @TODO modify request changing flow to ensure `response` is defined. */
                // @ts-expect-error
                response,
                /** @TODO modify request changing flow to ensure `fulfillmentType` is defined. */
                // @ts-expect-error
                fulfillmentType,
                wantedAtDay,
                action.wantedAt,
              ),
            );
        }

        break;
    }

    // Fire reconciliation modal if we've assigned a relevant error event
    if (errorEvent) {
      yield put(actions.initializeOrderReconciliation(errorMessage));
    }

    if (errorMessage.includes('prep time is outside menu hours')) {
      yield put(orderStatusActions.orderTimeUnavailableSet());
    }

    // If error results from a stored address, record the address ID
    const state: RootState = yield select();
    const deliveryAddress = assembleDeliveryObjectFromState(state);
    let additionalDetails: string;

    if (deliveryAddress?.id) {
      additionalDetails = `Stored Address ID: ${deliveryAddress.id}`;

      // If we run into an error setting conveyance, clear ID from address. At worst, we create a duplicate address and don't prevent checkout
      if (getInitializationErrorType(errorMessage) === InitializationErrorTypes.LOCK) {
        ErrorReporter.captureMessage(
          'User encountered error checking out with a stored address',
          'info',
          {
            additionalDetails,
            errorMessage,
          },
        );

        const location = getLocationFromState(state);
        const loggedOutAddress = Object.assign({}, deliveryAddress);
        delete loggedOutAddress.id;
        delete loggedOutAddress.default;

        /**
         * @TODO reconcile IConveyanceDeliveryAddress and
         * the SDK `DeliveryAddress` types.
         */
        yield put(
          conveyanceModeActions.setDeliveryAddress(
            // @ts-expect-error
            loggedOutAddress,
            location,
            false,
          ),
        );
      }
    }

    // KA event
    errorEvent = errorEvent || ERROR_MESSAGES.INIT_CHECKOUT_UNHANDLED_FAILURE;
    fireKAnalyticsError(
      errorEvent,
      error,
      preparedErrorResponse,
      // @ts-expect-error
      additionalDetails,
    );
  }
}

/**
 * Post-initialization API calls
 *
 */
function* postInitializeOrderSaga(action: ReturnType<typeof actions.postInitializeOrder>) {
  try {
    // Fetch wanted times for the basket
    yield put(
      timeslotsActions.fetchHandoffTimesByBasket(
        action.basket.id,
        action.basket.location.id,
        determineHourType(action.fulfillmentType),
        action.basket.order_asap,
        // @ts-expect-error
        action.wantedAtDay,
        action.type,
        action.wantedAt,
      ),
    );

    // Commerce Events
    yield put(
      commerceActions.commerceCheckoutBasketStaged(
        action.basketOrder,
        action.basket,
        action.fulfillmentType,
      ),
    );
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      `Failed to initialize the order for basket: ${action.basket.id}.`,
      error,
    );

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.POST_INIT_CHECKOUT_FAILURE, error, errorResponse);
  }
}

/**
 * Validate Basket
 *
 */
function* validateOrderSaga(action: ReturnType<typeof actions.validateOrder>) {
  const state: RootState = yield select();
  const guestUser = state.app.auth.guestUser;
  try {
    // Validate the patched checkout basket
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof validateBasket> = yield call(
      validateBasket,
      { basketId: action.basketId, locationId: action.locationId, guestUser },
      { client },
    );

    yield put(basketActions.success(response));

    // Success
    yield put(actions.orderPending());
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      `There was an issue validating your order for the following basket: ${action.basketId}.`,
      error,
    );

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.BASKET_VALIDATION_ERROR, error, errorResponse);
  }
}

/**
 * Add Tip
 *
 */
function* tipAddSaga(action: ReturnType<typeof actions.tipAdd>) {
  try {
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const response: SagaReturnType<typeof addTip> = yield call(
      addTip,
      { basketId, locationId, tipAmount: action.tipAmount },
      { client },
    );

    // Success
    yield put(actions.orderPending());
    yield put(basketActions.success(response));

    // Silently validate basket
    if (isUserLoggedInForCheckout(state.app.me.data, state.app.auth.guestUser)) {
      yield put(actions.validateOrder(basketId, locationId));
    }

    // KA Events
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.TIP_ADDED, {
      name: action.isCustom ? 'custom' : 'predefined',
      details: toDollars(action.tipAmount),
    });
  } catch (error) {
    yield put(actions.orderPending());

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.TIP_ADDING_ERROR,
      error,
    );
    yield put(actions.orderStatusMessageErrorSet(errorResponse.message));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.ADD_TIP_ERROR, error, errorResponse);
  }
}

/**
 * Set Utensils
 *
 */
function* utensilsSetSaga(action: ReturnType<typeof actions.utensilsSet>) {
  try {
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const response: SagaReturnType<typeof setUtensils> = yield call(
      setUtensils,
      { basketId, locationId, preference: action.utensils },
      { client },
    );

    // Success
    yield put(actions.orderPending());
    yield put(basketActions.success(response));

    genericEventHandler(GlobalEvents.GENERIC__CTA, {
      name: EventNames.UTENSILS_SET,
      details: action.utensils ? 'Added' : 'Removed',
    });

    genericEventHandler(GlobalEvents.BASKET__CUSTOMIZED, {
      name: EventNames.UTENSILS_SET,
      details: action.utensils ? 'Added' : 'Removed',
    });
  } catch (error) {
    yield put(actions.orderPending());

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.UTENSIL_SETTING_ERROR,
      error,
    );
    yield put(actions.orderStatusMessageErrorSet(errorResponse.message));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.UTENSILS_ERROR, error, errorResponse);
  }
}

/**
 * Set Basket Instructions
 *
 */
function* basketInstructionsSetSaga(action: ReturnType<typeof actions.basketInstructionsSet>) {
  try {
    const state: RootState = yield select();
    const { basketId, locationId } = getIdsFromState(state);
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof setInstructions> = yield call(
      setInstructions,
      { basketId, locationId, basket_instructions: action.basketInstructions },
      { client },
    );

    // Success
    yield put(actions.orderPending());
    yield put(basketActions.success(response));

    // TODO - add actual EVENT
    // fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, { name: ERROR_MESSAGES.TIP_ADDED, details: action.basketInstructions });
  } catch (error) {
    yield put(actions.orderPending());

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.BASKET_INSTRUCTIONS_SETTING_ERROR,
      error,
    );
    yield put(actions.orderStatusMessageErrorSet(errorResponse.message));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.BASKET_INSTRUCTIONS_ERROR, error, errorResponse);
  }
}

function* orderSubmissionDigitalWalletTriggerSaga(
  action: ReturnType<typeof actions.orderDigitalWalletTrigger>,
) {
  try {
    yield put(paymentActions.creditCardSet(action.paymentDetails));
    yield put(actions.orderSubmissionTrigger(action.message, action.captcha, action.queryClient));
  } catch (err) {
    const error = err as Error;

    yield put(actions.orderSubmissionError(error.message));
  }
}

/**
 * Trigger Order Submission
 *
 */
function* orderSubmissionTriggerSaga(action: ReturnType<typeof actions.orderSubmissionTrigger>) {
  try {
    const state: RootState = yield select();

    const orderData = assembleOrderDataFromState(state);

    const filteredOrderData = orderData.payment.filter(
      (payment: PaymentDetails) => payment.type !== PAYMENT_TYPES.STORED_VALUE,
    );

    const storedValue = orderData.payment?.find(
      (payment: PaymentDetails) => payment.type === PAYMENT_TYPES.STORED_VALUE,
    );

    orderData.payment = filteredOrderData;

    if (storedValue && typeof storedValue.amount === 'number') {
      orderData.gift_card = {
        amount_authorized: storedValue.amount,
      };
    }
    // not sure why we can't just call actions.orderSubmissionTrigger(action.captcha) to get the captcha value directly.
    const submissionTriggerProps = actions.orderSubmissionTrigger(
      action.message,
      action.captcha,
      action.queryClient,
    );

    yield put(
      actions.orderSubmissionStart(orderData, submissionTriggerProps.captcha, action.queryClient),
    );
  } catch (err) {
    const error = err as Error;
    yield put(actions.orderSubmissionError(error.message));

    // Fire KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.TRIGGER_SUBMIT_CHECKOUT_ERROR,
      details: error.message,
    });
  }
}

/**
 * Start Order Submission
 *
 */
function* orderSubmissionStartSaga(action: ReturnType<typeof actions.orderSubmissionStart>) {
  const state: RootState = yield select();
  const queryClient = action.queryClient;

  const orderStatus = state.app.orderStatus;
  if (orderStatus.utm_source) {
    action.submitBasketData.utm_source = orderStatus.utm_source;
  }

  const client = createHttpClient({ origin: getOrigin(window.location.host) });
  const { basketId, locationId } = getIdsFromState(state);

  try {
    const orderDetails: SagaReturnType<typeof submitOrder> = yield call(
      submitOrder,
      {
        basketId,
        locationId,
        orderData: action.submitBasketData,
        captcha: action.captcha,
      },
      { client },
    );

    // Write submitted basket to redux
    yield put(basketActions.confirmation());

    // Write order details to redux for further use in order-confirmation page
    yield put(globalActions.clearFeatureBag());
    if (queryClient) {
      let orderId = null;
      if (typeof orderDetails === 'object' && orderDetails !== null && 'order_id' in orderDetails) {
        orderId = (orderDetails as Record<string, unknown>).order_id;
      }
      queryClient.setQueryData(['order-confirmation', orderId], orderDetails);
    }

    // Fire complete order events
    // @ts-expect-error
    yield put(commerceActions.commerceCheckoutPurchase(orderDetails));

    // Go to confirmation page
    const urlObj: UrlObject = {
      pathname: ROUTES.ORDER_CONFIRMATION,
      // @ts-expect-error
      query: { id: orderDetails.order_id },
    };

    void Router.push(urlObj);
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.ORDER_SUBMISSION_ERROR,
      error,
    );
    const errorType = errorResponse?.error?.error;

    const userFacingErrorMessage =
      errorResponse.message?.indexOf('Payment(s) must fund Basket total.') > -1
        ? 'There was an issue applying your payment total. Please reset and try again.'
        : errorResponse.message;

    yield put(actions.orderSubmissionError(userFacingErrorMessage));

    // Derive event
    let gaAction = gaActions.checkoutError;
    let kaEventName = ERROR_MESSAGES.SUBMIT_CHECKOUT_ERROR;

    // Credit card-specific errors
    // e.g. Credit Card x-7858 Declined: Invalid Billing Address and/or Zip Code.
    // e.g. Credit Card x-9301 Declined: Credit card was not approved.
    // e.g. Credit Card x-3673 Declined: Invalid CVV Code.
    // e.g. Credit Card x-8431 Failed: Unsuccessful Payment Transaction.
    // e.g. A credit card is expired: 7 / 2020
    if (
      errorResponse.message?.indexOf('payment') > -1 ||
      errorResponse.message?.indexOf('Declined') > -1 ||
      errorResponse.message?.indexOf('expired') > -1 ||
      errorResponse.message?.indexOf('expiry') > -1 ||
      errorResponse.message?.indexOf('Unsuccessful Payment Transaction') > -1
    ) {
      // Olo Pay requires single time usage of the credit card token so we are refreshing token if submission failure
      if (state.app.paymentTypes?.credit_card_token.supported) {
        yield put(paymentActions.paymentTypeReset());
      }

      gaAction = gaActions.paymentDenied;
      kaEventName = ERROR_MESSAGES.PAYMENT_DENIED_ERROR;
    }

    // Ordering not available at location
    // e.g. location_not_available: Online ordering is unavailable at this time. Please use the location finder to try another location nearby.
    // e.g. Your order could not be placed. Please visit this location directly to place your order.
    if (
      errorType === 'location_not_available' ||
      errorResponse.message?.indexOf(
        'Your order could not be placed. Please visit this location directly to place your order.',
      ) > -1 ||
      errorResponse.message?.indexOf('We are currently unable to take your order.') > -1
    ) {
      gaAction = gaActions.locationNotAvailable;
      kaEventName = ERROR_MESSAGES.LOCATION_NOT_AVAILABLE;
    }

    // Basket is invalid
    // e.g. Your order cannot be ready by 8:30pm due to the time it will take to prepare your order. Please choose another available time.
    // e.g. Apologies, there is a problem on this order. Please update your order to remove any unavailable items to try this order again. "Vanilla" is not currently available on "Shakes".
    if (errorType === 'basket_not_valid') {
      gaAction = gaActions.basketNotValid;
      kaEventName = ERROR_MESSAGES.BASKET_NOT_VALID;
    }

    // GA Event
    fireGaEvent(gaCats.order, gaAction, {
      label: `${errorType}: ${errorResponse.message}`,
    });

    if (errorResponse) {
      errorResponse.message = `${errorResponse.message} basketId: ${basketId}`;
    }
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ORDER_SUBMISSION_FAILED, {
      name: kaEventName,
      error: errorResponse,
      // @ts-expect-error
      details: error,
    });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.INITIALIZE_ORDER, initializeOrderSaga);
  yield takeLatest(actions.POST_INITIALIZE_ORDER, postInitializeOrderSaga);
  yield takeLatest(actions.VALIDATE_ORDER, validateOrderSaga);
  yield takeLatest(actions.TIP_ADD, tipAddSaga);
  yield takeLatest(actions.UTENSILS_SET, utensilsSetSaga);
  yield takeLatest(actions.BASKET_INSTRUCTIONS_SET, basketInstructionsSetSaga);
  yield takeLatest(actions.ORDER_SUBMISSION_START, orderSubmissionStartSaga);
  yield takeLatest(
    actions.ORDER_SUBMISSION_DIGITAL_WALLET_TRIGGER,
    orderSubmissionDigitalWalletTriggerSaga,
  );
  yield takeLatest(actions.ORDER_SUBMISSION_TRIGGER, orderSubmissionTriggerSaga);
}
