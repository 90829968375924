import { KoalaEnv } from '@koala/sdk';
import { z } from 'zod';
import pkg from 'package.json';
import { isAndroidShell } from '@/utils/android';

const runtimeCredentials = z.object({
  baseUrl: z.string(),
  ENV: KoalaEnv,
  clientId: z.string(),
  clientSecret: z.string(),
  brandCmsUrl: z.string(),
});

/**
 * Returns the correct environment, Client ID, & Secret for the build.
 * The returned values depend on a few different factors:
 * 1. Are we in local development mode (i.e. running `yarn start:ordering`)?
 * 2. Are we in the Android Kotlin shell?
 * 3. Are we running in a deployed build?
 */
function setup(): z.infer<typeof runtimeCredentials> {
  const env = KoalaEnv.parse(process.env.NEXT_PUBLIC_ENV);

  // If we're in the Android Kotlin shell, use its Client ID & Secret.
  if (isAndroidShell()) {
    const config = runtimeCredentials.parse({
      ENV: env,
      baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
      clientId: window.KoalaAndroidShell.getClientId(),
      clientSecret: window.KoalaAndroidShell.getClientSecret(),
      brandCmsUrl: process.env.NEXT_PUBLIC_BRAND_CMS_URL,
    });

    return config;
  }

  /**
   * Otherwise, the build is deployed. The Client ID & Secret share
   * the same environment variable names across all environments.
   */
  const config = runtimeCredentials.parse({
    ENV: env,
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
    clientId: process.env.NEXT_PUBLIC_UNIFIED_KOALA_CLIENT_ID,
    clientSecret: process.env.NEXT_PUBLIC_UNIFIED_KOALA_CLIENT_SECRET,
    brandCmsUrl: process.env.NEXT_PUBLIC_BRAND_CMS_URL,
  });

  return config;
}

export const { ENV, baseUrl, clientId, clientSecret, brandCmsUrl } = setup();

export const BUILD_VERSION = pkg.version;

export const SENTRY_DSN =
  'https://7311b40ecb52ca970add98cd1b5fdf9f@o28552.ingest.us.sentry.io/4507334548717568';
