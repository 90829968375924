import { detect } from 'detect-browser';
import { type SupportedPaymentTypes, type DigitalWallet } from '@koala/sdk';
import { MOBILE } from '@/constants/styles';

/**
 * Check which platform is being used.
 * userAgent ex -> Mozilla/5.0 (iPhone; CPU iPhone OS 18_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 AppId/coo-mobile Platform/ios
 */
export const parseCustomUserAgent = () => {
  if (typeof window === 'undefined') return {};

  const userAgent = window.navigator.userAgent;

  const mobileRegex = /AppId\/coo[-_]mobile/i;
  const iosRegex = /Platform\/ios/i;
  const androidRegex = /Platform\/android/i;

  return {
    mobile: mobileRegex.test(userAgent),
    mobile_ios: iosRegex.test(userAgent),
    mobile_android: androidRegex.test(userAgent),
  };
};

interface mobileStyleOptions {
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  paddingBottom: string;
  overflow: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  backgroundColor: string;
}
export const getMobileStyles = (
  requestedOptions?: (keyof mobileStyleOptions)[],
): mobileStyleOptions => {
  const styles: mobileStyleOptions = MOBILE;

  // If no requested options are provided, return all styles
  if (!requestedOptions) {
    return styles;
  }

  // Return only the requested styles
  // @ts-expect-error {} is not mobileStyleOptions type
  return requestedOptions.reduce<mobileStyleOptions>((acc, key) => {
    if (key in styles) {
      acc[key] = styles[key];
    }
    return acc;
    // @ts-expect-error {} is not mobileStyleOptions type
  }, {});
};

export const getSupportedDigitalWallets = (
  digitalWallets: SupportedPaymentTypes['digital_wallet'],
) => {
  const userAgent = navigator.userAgent;
  const isCOOMobile_android = parseCustomUserAgent()?.mobile_android;
  const isCOOMobile_iOS = parseCustomUserAgent()?.mobile_ios;
  const isChrome = userAgent.includes('Chrome') && !/Edge|Edg/.test(userAgent);
  const isWebSafari = detect()?.name === 'safari';
  const isMobileBrowser_iOS = detect()?.name === 'ios';

  // The current logic is that we ONLY show either apple pay or google pay, not both at the same time.
  return digitalWallets?.supported_digital_wallets?.filter((wallet: DigitalWallet) => {
    let shouldFilterOut = false;

    // if web browser
    if (isChrome) {
      shouldFilterOut = wallet.name === 'apple_pay';
    }
    if (isWebSafari) {
      shouldFilterOut = wallet.name === 'google_pay';
    }

    // if mobile browser but not safari
    if (isMobileBrowser_iOS && userAgent.includes('Chrome')) {
      shouldFilterOut = wallet.name === 'apple_pay';
    }

    // if mobile browser and safari
    if (isMobileBrowser_iOS && !userAgent.includes('Chrome')) {
      shouldFilterOut = wallet.name === 'google_pay';
    }

    // if iOS app
    if (isCOOMobile_iOS) {
      shouldFilterOut = wallet.name === 'google_pay';
    }

    // if android app
    if (isCOOMobile_android) {
      shouldFilterOut = wallet.name === 'apple_pay';
    }

    return !shouldFilterOut;
  });
};
