import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { pageViewEventHandler } from '@/analytics/events';
import { ROUTES } from '@/constants/routes';
import authActions from '@/redux/auth/actions';
import globalActions from '@/redux/global/actions';
import meActions from '@/redux/me/actions';
import { removeSelectedPopularItem } from '@/services/recommendations.service';
import { type RootState } from '@/types/app';

/**
 * Global event handlers that dispatch Redux actions based on route changes.
 *
 * @returns null
 */
export const RouteHandler = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const activeTagIds = useSelector((state: RootState) => state.app.tags.activeTagIds);
  const basketOpen = useSelector((state: RootState) => state.app.global.basketOpen);
  const { persistentParameters, currentPath } = useSelector(
    (state: RootState) => state.app.global.routerStatus,
  );

  // Triggered when a route starts to change.
  const handleRouteChangeStart = () => {
    dispatch(showLoading('page-transition'));
    // Close account dropdown modal on route change
    dispatch(meActions.toggleAccountDropdown(false));
    // Close fulfillment modal on route change
    dispatch(globalActions.toggleFulfillmentModal(false));
    // Close delivery modal on route change
    dispatch(meActions.toggleReorderReceipt(false));
  };

  // Triggered when a route change is completed.
  const handleRouteChangeComplete = () => {
    if (currentPath !== window.location.pathname) {
      dispatch(globalActions.setRouterPathname(window.location.pathname));
    }
    let showBasket = false;

    if (router.route === ROUTES.STORE) {
      if (basketOpen || router.query.openCart === String(true)) {
        showBasket = true;
      }
    }

    /**
     * We reset the selected popular product if the user navigates
     * outside of the page -> store locator -> single store flow
     *
     * The store locator can exist at `/` or `/stores`, so we check both
     */
    if (![ROUTES.STORE, ROUTES.STORES, ROUTES.HOMEPAGE].includes(router.route)) {
      removeSelectedPopularItem();
    }

    // Ensure cart is always closed on route change unless it has the cartOpen query param
    dispatch(globalActions.toggleBasket(showBasket));

    if (typeof window !== 'undefined' && window.location) {
      // Consolidated pageview events
      pageViewEventHandler();
    }

    appendRouteParameters();

    window.setTimeout(() => {
      dispatch(hideLoading('page-transition'));
    }, 300);
  };

  useEffect(() => {
    // Listen to routeChangeStart and routeChangeComplete router events.
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    dispatch(globalActions.hydratePersistentParameters(router.query));

    if (typeof window === 'object') {
      window.onload = () => {
        dispatch(globalActions.setRouterPathname(window.location.pathname));
        dispatch(authActions.reloadUser());
      };
    }

    // Clean up the router listeners when the component unmounts.
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    appendRouteParameters();
  }, [persistentParameters, activeTagIds]);

  const appendRouteParameters = () => {
    if ('URLSearchParams' in window) {
      const newParameters = {
        ...persistentParameters, // add required persistent route parameters
        ...(activeTagIds && router.pathname === ROUTES.STORE && { filter: activeTagIds }), // add filter tag IDs
      };

      const newParameterKeys = Object.keys(newParameters);

      // ONLY append additional URL parameters if we have new persistent parameters or filter tags to work with
      if (newParameterKeys.length > 0) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        newParameterKeys.forEach((param) => {
          // @ts-expect-error `param` can't index `newParameters`.
          params.set(param, newParameters[param]);
        });

        const as = `${url.pathname}?${params.toString()}`;
        const newWindowHistoryState = {
          ...window.history.state,
          as,
        };

        // replace this point in browser history with updated 'as' value so you may click the back button as one would expect
        window.history.replaceState(newWindowHistoryState, '', as);
      }
    }
  };

  // This component doesn't need to return anything.
  return null;
};
