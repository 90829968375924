import { getStoredAddresses } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';

export function useSavedAddresses(enabled = true) {
  const client = createHttpClient({
    origin: getOrigin(window.location.host),
  });

  return useQuery({
    queryKey: ['loyalty-saved-addresses'],
    queryFn: () => getStoredAddresses({ client }),
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled,
  });
}
