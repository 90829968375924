import { type BasketItem, CONVEYANCE_TYPES } from '@koala/sdk';
import { createSelector } from '@reduxjs/toolkit';
import { type AnyAction } from 'redux';
import * as Sentry from '@sentry/react';
import globalActions from '../global/actions';
import orderStatusActions from '../orderStatus/actions';
import actions from './actions';
import { type IBasketState } from './types';
import { type RootState } from '@/types/app';
import {
  basketProductsWithOptionPricing,
  determineBasketFulfillment,
  getCartSubtotal,
  toDollars,
  checkEqualityBetweenLocalAndRemoteBasketItems,
} from '@/utils/basket';
import { getISODate } from '@/utils/dates';
import { genericGroupItemsByKey, sessionStorageBasket } from '@/utils/global';

export const initialState: IBasketState = {
  /** @TODO decouple `content` from SDK response type. */
  content: {
    // @ts-expect-error
    location: { id: null },
    basket_items: [],
    // @ts-expect-error
    special_instructions: null,
  },
  /**
   * This empty object type is a shim because models are no longer constructed
   * as empty classes. The app doesn't currently handle `undefined` locations
   * even though that should be the _actual_ empty state.
   *
   * @TODO handle `undefined` location initial state gracefully.
   */
  // @ts-expect-error
  location: {},
  orderSubmitting: false,
  rewardsLoading: false,
  checkoutBasket: null,
  checkoutOrder: null,
  appliedRewards: [], // This appears to be deprecated
  orderCompleted: false,
  fulfillment: null,
  createdAt: null,
  reorderBasketId: '',
  loading: false,
  orderConfirmMissingItems: false,
  itemAdded: false,
};

/**
 *
 * Since local basket items are synced more regularly with the ordering provider,
 * we need to ensure that some data that *isn't* returned from the Ordering API
 * is persisted even when local basket items are overwritten by those from the
 * ordering provider. This function carries over: the `cross_sell_id`,
 * `category_id`, and `category_label` properties.
 */
function persistBasketItemDetails(localItem: BasketItem, remoteItem?: BasketItem): BasketItem {
  if (!remoteItem) {
    return localItem;
  }

  return {
    ...remoteItem,
    product: {
      ...remoteItem.product,
      category_id: localItem.product.category_id,
      category_label: localItem.product.category_label,
      cross_sell_id: localItem.product.cross_sell_id,
    },
  };
}

export const basket = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.INIT_NEW_BASKET:
      sessionStorageBasket.set(
        Object.assign({}, initialState, {
          content: {
            location: {
              id: action.location && action.location.id,
            },
            basket_items: action.basketItems || [],
          },
          location: action.location,
          fulfillment: {
            type: determineBasketFulfillment(action.address),
            address: action.address || null,
            time_wanted: action.time_wanted,
          },
          createdAt: getISODate(),
        }),
      );

      return sessionStorageBasket.get();

    case actions.CLEAR_BASKET:
      const clearBasket = sessionStorageBasket.get();
      if (!clearBasket) {
        return state;
      }

      sessionStorageBasket.set(
        Object.assign({}, clearBasket, {
          content: Object.assign({}, clearBasket.content, {
            basket_items: [],
          }),
        }),
      );

      return sessionStorageBasket.get();

    // TODO - deprecate
    case actions.DEPERSONALIZE_BASKET:
      const depersonalizeBasket = sessionStorageBasket.get();

      if (depersonalizeBasket) {
        depersonalizeBasket.appliedRewards = initialState.appliedRewards;

        sessionStorageBasket.set(depersonalizeBasket);
        return depersonalizeBasket;
      }
      return state;

    case actions.HYDRATE_BASKET:
      const hydratedBasket = sessionStorageBasket.get() ?? initialState;
      Sentry.addBreadcrumb({
        category: 'Basket Reducer State',
        message: `Hydrating basket from sessionStorage or initialState`,
        data: {
          basketItemCount: hydratedBasket.content.basket_items.length,
          locationId: hydratedBasket.content.location.id,
        },
        level: 'info',
      });
      return hydratedBasket;

    case actions.ADD_ITEM:
      const basket = sessionStorageBasket.get();
      if (!basket) {
        return state;
      }
      const basketItems = [...basket.content.basket_items];
      if (typeof action.index === 'undefined') {
        basketItems.push(action.item);
      } else {
        basketItems[action.index] = persistBasketItemDetails(
          basketItems[action.index],
          action.item,
        );
      }

      sessionStorageBasket.set(
        Object.assign({}, basket, {
          content: Object.assign({}, basket.content, {
            basket_items: basketItems,
          }),
        }),
      );

      return sessionStorageBasket.get();

    case actions.REMOVE_ITEM:
      const basketContent = sessionStorageBasket?.get()?.content;
      if (!basketContent) {
        return state;
      }
      basketContent.basket_items.splice(action.index, 1);

      sessionStorageBasket.set(
        Object.assign({}, sessionStorageBasket.get(), {
          content: Object.assign({}, basketContent, {
            basket_items: basketContent.basket_items,
          }),
        }),
      );

      return sessionStorageBasket.get();

    case actions.REPLACE_BASKET:
      sessionStorageBasket.set(
        Object.assign({}, initialState, {
          content: {
            location: {
              id: action.location.id,
            },
            basket_items: action.basketItems.length ? action.basketItems : [],
          },
          location: action.location,
          fulfillment: {
            type: action.address ? CONVEYANCE_TYPES.DELIVERY : CONVEYANCE_TYPES.PICKUP,
            address: action.address || null,
          },
          createdAt: action.createdAt,
        }),
      );

      return sessionStorageBasket.get();

    case actions.ADD_REWARDS:
      return Object.assign({}, state, {
        rewardsLoading: true,
      });

    case actions.REWARDS_SUCCESS:
      return Object.assign({}, state, {
        rewardsLoading: false,
        appliedRewards: action.appliedRewards,
      });
    case actions.REMOVE_REWARD:
      return Object.assign({}, state, {
        appliedRewards: [],
      });
    case actions.UPDATE_BASKET_FULFILLMENT:
      // Updating basket fulfillment needs to be persisted in both sessionStorage
      // as well as the current redux store - where ephemeral, checkout-screen
      // specific information is stored and then cleared when a user navigates
      // away from checkout.
      const newState = Object.assign({}, state, {
        fulfillment: action.fulfillment,
      });

      sessionStorageBasket.set(
        Object.assign({}, sessionStorageBasket.get(), {
          fulfillment: action.fulfillment,
        }),
      );

      return newState;

    case actions.BASKET_ITEMS_SYNCED_WITH_STORE:
      const remoteBasketItems = action.payload as BasketItem[];

      const updatedState = {
        ...state,
        content: {
          ...state.content,
          basket_items: state.content.basket_items.map((item) => {
            // a local basket and a remote basket are not guaranteed to be in the same order
            // need to search for matching remote item
            const remoteItem = remoteBasketItems.find((remote) =>
              checkEqualityBetweenLocalAndRemoteBasketItems(item, remote),
            );
            return persistBasketItemDetails(item, remoteItem);
          }),
        },
      };

      const localBasket = sessionStorageBasket.get();
      if (!localBasket) {
        return state;
      }

      sessionStorageBasket.set({
        ...localBasket,
        content: {
          ...localBasket.content,
          basket_items: localBasket.content.basket_items.map((item: Omit<BasketItem, 'id'>) => {
            // a local basket and a remote basket are not guaranteed to be in the same order
            // need to search for matching remote item
            const remoteItem = remoteBasketItems.find((remote) =>
              checkEqualityBetweenLocalAndRemoteBasketItems(item, remote),
            );
            return persistBasketItemDetails(item, remoteItem);
          }),
        },
      });

      return updatedState;

    case actions.BASKET_SUCCESS:
      let checkoutBasketState = { ...action.checkoutBasket };
      if (action.checkoutBasket.supports_price_based_prep_times) {
        checkoutBasketState = {
          ...checkoutBasketState,
          earliest_ready_time_at_location: action.checkoutBasket.prep_time_changed
            ? checkoutBasketState.earliest_ready_time_at_location
            : null,
        };
      }
      sessionStorageBasket.set(
        Object.assign({}, sessionStorageBasket.get(), {
          checkoutBasket: checkoutBasketState,
        }),
      );
      return Object.assign({}, state, {
        checkoutBasket: checkoutBasketState,
      });

    // This will fire whenever a basket call fails
    case actions.COMPLETED:
      return Object.assign({}, state, {
        orderSubmitting: false,
      });

    // Order Submission Actions
    case actions.SUBMIT_CHECKOUT_TRIGGERED:
      return Object.assign({}, state, {
        orderSubmitting: action.orderSubmitting,
      });
    case actions.ORDER_CONFIRMATION:
      const order = sessionStorageBasket.get();

      sessionStorageBasket.set(
        Object.assign({}, order, {
          orderSubmitting: false,
          orderCompleted: true,
        }),
      );
      return sessionStorageBasket.get();

    // Basket Rehydration
    case actions.REHYDRATE_LOCAL_STORAGE_LOCATION:
      sessionStorageBasket.set(
        Object.assign({}, sessionStorageBasket.get(), {
          location: action.location,
        }),
      );
      return sessionStorageBasket.get();

    case actions.REVALIDATE_BASKET_ITEMS_SUCCESS:
      sessionStorageBasket.set(action.basket);

      return action.basket as IBasketState; // necessary to coerce types

    case orderStatusActions.DESTROY_ORDER:
      return Object.assign({}, state, {
        checkoutBasket: initialState.checkoutBasket,
      });

    // Destroy Basket
    case actions.DESTROY_BASKET:
      // Remove the basket from sessionStorage
      sessionStorageBasket.remove();

      // Reset redux
      return initialState;
    case actions.REORDER_BASKET:
      return Object.assign({}, state, {
        reorderBasketId: action.order.id,
        loading: true,
      });
    case globalActions.TOGGLE_FULFILLMENT_MODAL:
    case actions.REORDER_BASKET_SUCCESS:
    case actions.REORDER_BASKET_FAILURE:
      return Object.assign({}, state, {
        reorderBasketId: '',
        loading: false,
      });
    case actions.REORDER_BASKET_REVIEW_MISSING_ITEMS:
      return Object.assign({}, state, {
        orderConfirmMissingItems: true,
        loading: false,
      });
    case actions.BASKET_ITEM_SUCCESS:
      return {
        ...state,
        itemAdded: action.value,
      };
    default:
      return state;
  }
};

export const selectCart = createSelector(
  (state: RootState) => state.app.global,
  (state: RootState) => state.app.organization,
  (state: RootState) => state.app.basket,
  (state: RootState) => state.app.cmsConfig,
  (state: RootState) => state.app.menu,

  (global, organization, basket, config, menu) => {
    const showUpsells = Boolean(organization.organization.upsell_driver_id);

    const items = basketProductsWithOptionPricing(basket.content, menu.basketMenu);

    const showFoodHalls = config.webConfig.admin.food_hall;

    const groupedItems =
      showFoodHalls &&
      // @ts-expect-error
      genericGroupItemsByKey(items, 'final.filter_tags[0].label').filtersObject;

    const subtotal = getCartSubtotal(items);

    const deliveryMinNotMet =
      basket.fulfillment?.type === CONVEYANCE_TYPES.DELIVERY &&
      subtotal < (basket?.location?.delivery_minimum ?? 0);

    const deliveryOnlyNoAddress = !basket.fulfillment?.address && basket.location.is_delivery_only;
    const isCheckoutDisabled = deliveryMinNotMet || deliveryOnlyNoAddress;

    return {
      basketOpen: global.basketOpen,
      deliveryMinimum: toDollars(basket.location?.delivery_minimum ?? 0),
      groupedItems,
      isCheckoutDisabled,
      items,
      requiresDeliveryMinimum: deliveryMinNotMet,
      showDietaryPreferences: config.webConfig.menu.dietary_preferences_enabled,
      showSpecialInstructions: config.webConfig.product_detail.special_instructions_enabled,
      showProductImages: config.webConfig.sidecart.show_product_images,
      showFoodHalls,
      showUpsells,
      subtotal,
    };
  },
);
